import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../../src/environments/environment";
import { map, tap } from "rxjs/operators";
import { ShareService } from "sr-fe-core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class loginService {
    public loginUser: any;
    login: any;
    static supplierAPIUrl = environment.supplierAPIUrl;
    private _loginDetails$ = new ReplaySubject<any>(1);
    
    constructor(private _httpClient: HttpClient, private _shareService: ShareService) {
        this.getLoginData();
    }

    getLoginData() {
        const url = `/invoice/loginData`;
        const mUserId = this._shareService.userInfo["email"];
        console.log(mUserId);
        return this._httpClient.post(loginService.supplierAPIUrl + url, { user: mUserId }).pipe(
            tap(response => {
                this._loginDetails$.next(
                    response['data']
                );
                console.log(this._loginDetails$);

            })
        );
        // return this._httpClient.get<any>(loginService.supplierAPIUrl + url).pipe(
        //     tap(response => {
        //         this._loginDetails$.next(
        //             response['data']
        //         );
        //         console.log(this._loginDetails$);

        //     })
        // );

    }

    getLogin(): Observable<any> {
        return this._loginDetails$.asObservable();
    }
}