import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { uniqueId } from "lodash";
import { environment } from "../../../../../src/environments/environment";
@Injectable()
export class InvoiceService {
  static invoiceTableData: any[];
  static invoiceHederDetails: any = {};
  static InvoiceDetails: any[] = [];
  static PurchaseOrderList: any[];
  static softcopyData: any;
  static tableType;
  static isPoBasedInvoice: boolean;

  static overlayTableCoordinates: any[];
  static supplierAPIUrl = environment.supplierAPIUrl;
  static clientApi =environment.client_api;
  static xAuthToken =environment.x_auth;
  static url =environment.aider_url;

  public invoice_extraction ="/invoice-extraction";
  public document_rawdata ="/document-rawdata";

    fileUploadUrl: string = InvoiceService.url+this.invoice_extraction
    // "https://aider.applexus.com/api/v1/invoice-extraction";
  fileUploadResponse: any;

  static reviewInvoice: any;

  constructor(private _httpClient: HttpClient) {}

  uploadInvoiceFile(file: any) {
    const formData = new FormData();
    formData.append("file", file);
    const headers = new HttpHeaders()
      .set("Client-Api", InvoiceService.clientApi)
      .set("x-auth-token", InvoiceService.xAuthToken);
    // .set('x-doc-schema', '10002')

    return this._httpClient.post(this.fileUploadUrl, formData, { headers });
  }

  clearReviewINvoice() {
    InvoiceService.reviewInvoice = null;
    localStorage.removeItem("reviewInvoice");
  }

  getImage(guid) {
    const url = InvoiceService.url+this.document_rawdata +`/${guid}`;
    const headers = new HttpHeaders()
      .set("Client-Api", InvoiceService.clientApi)
      .set("x-auth-token", InvoiceService.xAuthToken)
      .set("x-doc-schema", "10002");

    return this._httpClient.get(url);
  }

  getLineItems(uid) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl +  "/invoice/line-items",
      { uid: uid }
    );
  }

  getInvoiceList(advancedSearch = {}) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/list",
      {
        advancedSearch: advancedSearch,
      }
    );
   
    // return this._httpClient.get("assets/files/invoice.json");
  }

  getInvoicePdf(uid) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/get-pdf",
      { uid: uid }
    );
  }

  saveCollabMessage(uid, message) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/collab-message/save",
      { uid: uid, message: message }
    );
  }

  deleteCollabMessage(msgId) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/collab-message/delete",
      { id: msgId }
    );
  }

  uploadAttachment(fileName, fileType: string, base64: any, uid) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/attachment/upload",
      { fileName: fileName, fileType: fileType, base64: base64, uid: uid }
    );
  }

  uploadInvAttachment(fileName, fileType: string, base64: any, uid) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/attachment/upload",
      { fileName: fileName, fileType: fileType, base64: base64, uid: uid }
    ).toPromise();
  }
  deleteAttachment(attachmentId) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/attachment/delete",
      { attachmentId: attachmentId }
    );
  }

  getAttachments(uid) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/attachment/list",
      { uid: uid }
    );
  }

  getAttachmentById(uid, attachmentId) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/attachment/id",
      { uid: uid, attachmentId }
    );
  }

  createInvoiceByPdf(fileName, pdfString) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/create-invoice/pdf",
      {
        fileName: fileName,
        base64String: pdfString,
      }
    );
  }
  createInvoiceByForm(data) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl +"/invoice/create-invoice/",
      { data: data }
    );
  }

  getCompanyList(data?) {
    return this._httpClient.get(
      InvoiceService.supplierAPIUrl +"/invoice/company-list",
      {}
    );
  }
  
  getpaymentTermsSet(data?) {
    return this._httpClient.get(
      InvoiceService.supplierAPIUrl +"/invoice/paymentTerms-list",
      {}
    );
  }
  getvendorList(data?) {
    return this._httpClient.get(
      InvoiceService.supplierAPIUrl +"/invoice/vendor-list",
      {}
    );
  }

  getcurrencyList(data?) {
    return this._httpClient.get(
      InvoiceService.supplierAPIUrl +"/invoice/currency-list",
      {}
    );
  }

  createInvoiceFormFields(loginSupplier, mInvType) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/create-invoice/fields",
      {Supplier: loginSupplier, InvType: mInvType}
    );
  }

  getPODetails(mEBELN, loginSupplier) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/create-invoice/podetails",
      {EBELN: mEBELN, LIFNR: loginSupplier.vendorId, BUKRS: loginSupplier.companyCode}
    );
  }
  
  getTaxDetails(mBUTXT, mUID) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/create-invoice/taxdetails",
      {BUTXT: mBUTXT, UID: mUID}
    );
  }
  
  getAdditionalItems(DCSID, EBELN, loginSupplier) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/additional-items",
      {companyCode: loginSupplier.companyCode, InvType: DCSID, PONumber: EBELN}
    );
  }
  
  getAddlItemDetails(arrPOItems, loginSupplier) {
    return this._httpClient.post(
      InvoiceService.supplierAPIUrl + "/invoice/create-invoice/addlitemdetails",
      {POItems: arrPOItems, Supplier: loginSupplier}
    );
  }


}
