/**
 * Purpose : To keep only authentication
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent, AlwaysAuthGuardService } from 'sr-fe-core';
// import { GeneralSettingsModule, PaymentGatewayModule,StoreManagementModule, UserManagementModule, RoleManagementModule ,StockParameterModule, KpiManagementModule, CachingModule} from 'projects/sr-admin/src/public_api';
import { GeneralSettingsModule, PaymentGatewayModule, StoreManagementModule, UserManagementModule, RoleManagementModule, StockParameterModule, KpiManagementModule, CachingModule } from 'sr-fe-admin';


const routes: Routes = [
    {
        path: 'simple-retail',
        component: MainLayoutComponent,
        // canActivateChild: [AlwaysAuthGuardService],
        children: [
            {
                path: 'administration',
                loadChildren: () => GeneralSettingsModule
                // loadChildren: 'sr-admin#GeneralSettingsModule'
            },
            {
                path: 'administration/payment-gateway',
                loadChildren: () => PaymentGatewayModule
                // loadChildren: 'sr-admin#PaymentGatewayModule'
            },
            {
                path: 'administration/stores',
                loadChildren: () => StoreManagementModule
                // loadChildren: 'sr-admin#StoreManagementModule'
            },
            {
                path: 'administration/users',
                loadChildren: () => UserManagementModule
                // loadChildren: 'sr-admin#UserManagementModule'
            },
            {
                path:'administration/role',
                loadChildren: () => RoleManagementModule
                // loadChildren: 'sr-admin#RoleManagementModule'
            },
            // {
            //     path:'administration/stock',
            //     // loadChildren: () => StockParameterModule
            //     loadChildren: 'sr-admin#StockParameterModule'
            // },
            // {
            //     path:'administration/manage-kpi',
            //     // loadChildren: () => KpiManagementModule
            //     loadChildren: 'sr-admin#KpiManagementModule'
            // },
            // {
            //     path:'administration/manage-cache',
            //     // loadChildren: () => CachingModule
            //     loadChildren: 'sr-admin#CachingModule'
            // },
        ]
    }
  
  ];

// const routes: Routes = [
//     {
//         path: 'settings',
//         component: MainLayoutComponent,
//         children: [
//             {
//                 path: '',
//                 loadChildren: () => GeneralSettingsModule
//             },
//             {
//                 path: 'payment-gateway',
//                 loadChildren: () => PaymentGatewayModule
//             },
//             {
//                 path: 'stores',
//                 loadChildren: () => StoreManagementModule
//             },
//             {
//                 path: 'users',
//                 loadChildren: () => UserManagementModule
//             },
//             {
//                 path:'role',
//                 loadChildren: () => RoleManagementModule
//             }
//         ]
//     }
// ];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AdministrationRoutingModule { }
